import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { graphql, Script } from "gatsby";
import { useWindowSize } from "../hooks";
import {
  StickyCallToAction,
  Hero,
  Contact,
  WhyChoose,
  EventBanner,
  Questions,
  RenderWhenReady,
  SEO,
} from "../components";

const IndexPage = ({ data }) => {
  const {
    introduction,
    image,
    mobileImage,
    questions,
    reasons,
    eventBannerImage,
    eventCalendarLink,
    socialThumbnail,
  } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const { windowWidth } = useWindowSize();
  const [showCrispCTA, setShowCrispCTA] = useState(false);
  return (
    <Container>
      <Script id="crisp">{`window.$crisp=[];window.CRISP_WEBSITE_ID="c1b5faf1-3950-478e-aaa5-3c85dc58b4de";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}</Script>
      <AnimatePresence>
        <StickyCallToAction />
      </AnimatePresence>
      <Hero
        introduction={introduction}
        image={image}
        mobileImage={mobileImage}
      />
      <RenderWhenReady>
        {windowWidth <= 640 && (
          <EventBanner
            image={eventBannerImage}
            calendarLink={eventCalendarLink}
          />
        )}
      </RenderWhenReady>
      <Contact />
      <RenderWhenReady>
        {windowWidth > 640 && (
          <EventBanner
            image={eventBannerImage}
            calendarLink={eventCalendarLink}
          />
        )}
      </RenderWhenReady>
      <WhyChoose reasons={reasons} />
      <Questions questions={questions} setShowCrispCTA={setShowCrispCTA} />
      <AnimatePresence mode="wait">
        {showCrispCTA && (
          <CrispCTA
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{
              delay: 1,
              duration: 0.3,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            tabIndex="0"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              document.querySelector(".cc-nsge").click();
            }}
          >
            <span>Chat with us</span>
          </CrispCTA>
        )}
      </AnimatePresence>
    </Container>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "/" } } }) {
      edges {
        node {
          frontmatter {
            introduction
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  quality: 100
                  layout: FULL_WIDTH
                )
              }
            }
            mobileImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  quality: 100
                  layout: FULL_WIDTH
                )
              }
            }
            socialThumbnail {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            eventCalendarLink
            eventBannerImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  quality: 100
                  layout: FULL_WIDTH
                )
              }
            }
            eventCalendarLink
            reasons {
              title
              link
              description
            }
            questions {
              callToAction
              actionLink
              title
              points {
                text
              }
              vimeoCode
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    quality: 100
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
`;

const CrispCTA = styled(motion.div)`
  cursor: pointer;
  position: fixed;
  bottom: 1.5rem;
  right: 5.5rem;
  z-index: 100;
  padding: 1rem;
  span {
    color: var(--wc-highlight-color);
    font-size: 1rem;
  }
`;

export default IndexPage;

export const Head = () => (
  <>
    <title>
      Waterstone Clinic - Ireland's Leading Fertility Clinic Since 2002
    </title>
    <meta
      name="og:site_name"
      content="Waterstone Clinic - Ireland's Leading Fertility Clinic Since 2002"
    />
    <meta name="description" content="Get in touch today" />
    <meta name="og:locale" content="en-GB" />
    <meta name="og:url" content="https://idliketoknow.ie/" />
    <meta
      name="og:title"
      content="Waterstone Clinic - Ireland's Leading Fertility Clinic Since 2002"
    />
    <meta name="og:description" content="Get in touch today" />
    <meta name="og:type" content="website" />
    <meta
      name="og:image"
      content="https://idliketoknow.ie/social-thumbnail.png"
    />
    <meta
      name="og:image:url"
      content="https://idliketoknow.ie/social-thumbnail.png"
    />
    <meta
      name="og:image:secure_url"
      content="https://idliketoknow.ie/social-thumbnail.png"
    />
    <meta
      name="og:image:alt"
      content="Waterstone Clinic - Ireland's Leading Fertility Clinic Since 2002"
    />
    <meta name="og:image:width" content="1200" />
    <meta name="og:image:height" content="640" />
    <meta name="image" content="https://idliketoknow.ie/social-thumbnail.png" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:image"
      content="https://idliketoknow.ie/social-thumbnail.png"
    />
    <meta
      name="twitter:image:alt"
      content="Waterstone Clinic - Ireland's Leading Fertility Clinic Since 2002"
    />
    <meta
      name="twitter:title"
      content="Waterstone Clinic - Ireland's Leading Fertility Clinic Since 2002"
    />
    <meta name="twitter:url" content="https://idliketoknow.ie/" />
    <meta name="twitter:description" content="Get in touch today" />
    <meta name="twitter:site" content="www.twitter.com/waterstoneivf" />
    <meta name="twitter:creator" content="@waterstoneivf" />
    <meta name="author" content="Agency X" />
  </>
);
